var gmarkers1 = [];
var refineries = {
}
var infowindow;
var map, markers, marker;
// var centerPoint = '24.862284,66.997175';
var longtitude = '24.862284';
var latitude = '66.997175';
var zoom_level = 2;

var players = {};
var datas = [];
// videoId: 'CaTYjvTf7-E',
var player, iframe;
var _ytPlayer = document.querySelector.bind(document);

var curdomain = location.hostname;
var livedomain = ['simedarbyoils.com', 'www.simedarbyoils.com'];
if (livedomain.indexOf(curdomain) >= 0) {
	var apipath = "https://www.simedarbyoils.com/api/public/";
} else {
	var apipath = "https://simedarbyoils.workdoneby.us/api/public/";
}

var markerParam = getUrlParameter('marker');
// console.log("top markerParam=="+markerParam);
var ajaxmarkers = [];

$(window).on('load', function () {
	$('.submenu').removeClass('hide');
	//console.log('All assets are loaded')
})

$(document).ready(function () {
	$(document).foundation();
	var contactUsProduct = getCookie('contactUsProduct');

	if (contactUsProduct.length > 0) {
		var contactUsProductArr = contactUsProduct.split(',');
		if (contactUsProductArr.length > 0) {
			$.each(contactUsProductArr, function (i) {
				datas.push(contactUsProductArr[i]);
			});
		}
	}


	// Click Navi
	$(".js-anchor-link").click(function () {
		var id = $(this).attr("id");
		if (datas.indexOf(id) < 0) {
			datas.push($(this).attr('id'));
		}
		console.log("datas=" + datas);
		setCookie('contactUsProduct', datas);
	});

	if ($('#contactUsForm').length > 0) {
		if (contactUsProduct.length > 0) {
			$('#contactUsForm').find(':checkbox[name^="product[]"]').each(function () {
				var id = $(this).attr("id");
				$(this).prop("checked", (contactUsProduct.indexOf(id) >= 0));
			});
		}
	}

	$('#main-menu').on('show.zf.dropdownmenu', function () {
		var dropdown = $(this).find('.is-dropdown-submenu');
		dropdown.css('display', 'none');
		dropdown.slideDown(400);
	});

	$('#main-menu').on('hide.zf.dropdownmenu', function () {
		var dropdown = $(this).find('.is-dropdown-submenu');
		dropdown.css('display', 'inherit');
		dropdown.slideUp(200);
	});


	Modernizr.on('webp', function (result) {
		if (result) {

		} else {

		}
	});

	$('.slick-slider').slick({
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 3000,
		adaptiveHeight: true
	});

	// Show or hide the sticky footer button
	$(window).scroll(function () {
		if ($(this).scrollTop() > 20) {
			$('.btn-to-top').fadeIn(20);
		} else {
			$('.btn-to-top').fadeOut(20);
		}
	});

	// Animate the scroll to top
	$(document).on("click", ".btn-to-top", function (event) {
		event.preventDefault();

		$('html, body').animate({ scrollTop: 0 }, 300);
	})


	//map-moreinfo
	var clickAction = false;
	var currentTab = null;
	$(document).on("click", ".map-moreinfo", function (event) {
		event.preventDefault();
		location.hash.replace('#','');
		var ID = $(this).attr("id");
		var target = $( $(this).attr('href') );
		var target_tab = $( $(this).attr('data-target-tab') );
		var expanded = target_tab.siblings('.a-collapse');
		if ( expanded.length <= 0 ) {

			$( target_tab ).trigger('click');
		}

		$('html, body').stop();
		$('html, body').animate({
			scrollTop: $( target ).offset().top - 100
		}, 1000);
	});


	//accordion
	$('.accordion-group').find('.btn-accordion').click(function (e) {
		event.preventDefault();
		var x = $(this).offset().top;
		$('html,body').animate({ scrollTop: x - 100 }, 500);

		$(".accordion-group").not($(this).parent()).removeClass('a-collapse');
		$(this).parent().toggleClass('a-collapse');

		//Expand or collapse this panel
		$(this).next().slideToggle('fast').toggleClass('a-collapse');

		// //Hide the other panels
		$(".content-accordion").not($(this).next()).slideUp('fast').removeClass('a-collapse');
		// $(".accordion-group").removeClass('a-collapse');

	});


	//traceability-dashboard.html page
	$('.accordion-group').find('.readmore-accordion').click(function (e) {

		//$(".accordion-group").not($(this).parent()).removeClass('a-collapse');
		$(this).parent().toggleClass('a-collapse');

		//Expand or collapse this panel
		$(this).next().slideToggle('fast').toggleClass('a-collapse');

	});


	$(document).on("click", ".map-filter-search", function () {
		//console.log('clicked');
		$(".map-filter-group").toggle("slow", function () {
			// Animation complete.
		});
	});


	//anchor
	$(document).on("click", ".js-anchor-link", function (e) {
		e.preventDefault();
		var target = $($(this).attr('href'));
		if (target.length) {
			var scrollTo = target.offset().top;
			$('html, body').animate({ scrollTop: target.offset().top - 100 }, 1000);
		}
	});


	$(document).on("click", ".btn-view-more", function (e) {
		e.preventDefault();
		$('.container-side-bar').css("left", "0");
		$('body').css('overflow', 'hidden').css('position', 'fixed');
	});

	$(document).on("click", ".btn-sidebar-close", function (e) {
		e.preventDefault();
		$('.container-side-bar').css("left", "-640px");
		$('body').css('overflow', 'auto').css('position', 'relative');
	});

	$(window).resize(function () {
		if (window.innerWidth < 640) {
			$(document).on("click", ".js-anchor-link", function () {
				$('.container-side-bar').css("left", "-640px");
				$('body').css('overflow', 'auto').css('position', 'relative');
				return false;
			});
		}
	}).resize(); // trigger resize event

	//map
	if ($('#map-canvas').length) {
		$.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAa8AgyUpPedHzuofrTvw0g6_UzvndIa0U', function (data, textStatus, jqxhr) {
			var marker_json_url = $('#map-canvas').attr('data-json');
			$.ajax({
				url: marker_json_url,
				type: 'get',
			}).done( function(data) {
				console.log( data );
				ajaxmarkers = data;
				initialize();

				setTimeout(function () {
					if (markerParam) {
						markerParam = markerParam - 1;
						google.maps.event.trigger(gmarkers1[markerParam], 'click');
					}
					var param =  getUrlParameter('refinery');
					if ( param.length ) {
						if ( refineries[param] ) {
							google.maps.event.trigger( refineries[param], 'click');
						}

					}
					
				}, 1000);
			} );
		});

		
	}

	$('.btn-filter').click(function (event) {
		event.preventDefault();
		$('.btn-filter').removeClass('active');
		var id = $(this).attr("id");
		// console.log('click sss');

		var center = new google.maps.LatLng(longtitude, latitude);
		map.setZoom(zoom_level);
		map.setCenter(center);

		filterMarkers(id);
		$(this).addClass('active');
	});

	$(window).scroll(function () {
		if ($(this).scrollTop() == 0) {
		} else if (isVisible($('footer'))) {
			$('.btn-view-more').css('position', 'relative');
		} else {
			$('.btn-view-more').css('position', 'fixed');

		}
	});

	$('#about-player').hide();
	$('#play_vid').click(function () {
		$('#about-player').show();
		$('#play_vid').hide();
	});

	$('.hotspot-wrap').on('click', function () {
		$('.hotspot-info').not($(this).next()).removeClass('active');
		$(this).next().toggleClass('active');
	});

	$('.btn-hotspot-close').on('click', function () {
		$('.hotspot-info').not($(this).parent()).removeClass('active');
		$(this).parent().toggleClass('active');
	});


	//carousel video
	$(".slick-video").on("beforeChange", function (event, slick) {
		// console.log("beforeChange");
		var currentSlide, player, command;

		// Find the current slide element and decide which player API we need to use.
		currentSlide = $(slick.$slider).find(".slick-current");
		var thumbnail_path = currentSlide.data("thumbnail");

		currentSlide.html('<img src="' + thumbnail_path + '" />');
	});

	$(".slick-video").on("afterChange", function (event, slick) {
		// console.log("afterChange");

		var currentSlide, player, command;

		// Find the current slide element and decide which player API we need to use.
		currentSlide = $(slick.$slider).find(".slick-current");

		var id = currentSlide.data("ytid");
		currentSlide.html('<iframe class="youtube" src="https://www.youtube-nocookie.com/embed/' + id + '?rel=0&amp;autoplay=1&amp;enablejsapi=1" frameborder="0" allowfullscreen></iframe>');

		// Get the iframe inside this slide.
		player = currentSlide.find("iframe").get(0);

		// Special parametrs for Youtube API
		command = {
			"event": "command",
			"func": "playVideo"
		};

		//check if the player exists.
		if (player != undefined) {

			//post our command to the iframe.
			player.contentWindow.postMessage(JSON.stringify(command), "*");
		}

	});

	$('.slick').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var data = { "event": "command", "func": "pauseVideo", "args": "" };
		var message = JSON.stringify(data);
		$("iframe", slick.$slides[currentSlide])[0].contentWindow.postMessage(message, '*');
	});

	//start the slider
	$(".slick-video").slick({
		// infinite: true,
		arrows: true,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		centerMode: true,
		centerPadding: '25%',
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					centerMode: false,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});

	//run the fitVids jQuery plugin to ensure the iframes stay within the item.
	// $('.item').fitVids();

	$('.slick-related-news').slick({
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		arrows: false,
		autoplay: false,
		adaptiveHeight: false
	});

	// progressbar
	function animateElements () {
		$('.progressbar').each(function () {
			var elementPos = $(this).offset().top;
			var topOfWindow = $(window).scrollTop();
			var percent = $(this).find('.circle').attr('data-percent');
			var percentage = parseInt(percent, 10) / parseInt(100, 10);
			var animate = $(this).data('animate');
			if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
				$(this).data('animate', true);
				$(this).find('.circle').circleProgress({
					startAngle: -Math.PI / 2,
					value: percent / 100,
					thickness: 25,
					size: 300,
					fill: {
						color: '#808080'
					}
				})
					.on('circle-animation-progress', function (event, progress, stepValue) {
						$(this).find('.progressbar-percent').text((stepValue * 100).toFixed(1) + "%");
					}).stop();
			}
		});

		$('.skillbar-wrap').each(function () {
			var elementPos = $(this).offset().top;
			var topOfWindow = $(window).scrollTop();
			var animate = $(this).data('animate');
			if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
				$(this).data('animate', true);

				var dataskillbar = $(this).find('.skillbar');
				$(this).find('.skillbar-bar').animate({
					width: $(dataskillbar).attr('data-percent')
				}, 5000);

				//decimal numbers
				var $this = $(this).find('.count')
				var $word = $this.data('word')

				$(this).prop('Counter', 0).animate(
					{
						Counter: $this.data('value')
					}, {
					duration: 5000,
					easing: 'linear',
					easing: 'swing',
					progress: function () {
						// $this.text(this.Counter.toFixed(1));
						$this.text(this.Counter.toFixed(1) + $word);
					},
					// step: function () {
					// 	// $this.text(Math.ceil(now)+"% "+$word);
					// 	$this.find(".traceable-word").text(this.Counter.toFixed(1)+"% "+$word);
					// 	// $this.text(this.Counter.toFixed(1)+ "sdasdsa");
					// }
				});

			}
		});

	}

	// Show animated elements
	animateElements();
	$(window).scroll(animateElements);

	//infowindow close zoom out
	$(document).on('click', '.gm-style-iw-c button', function () {
		infowindow.close();
		console.log('close');
		map.setZoom(zoom_level);
	});


}); //end document ready


/**
* Function for Youtube
*/
function youtube_parser (url) {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return (match && match[7].length == 11) ? match[7] : false;
}

function onYouTubeIframeAPIReady () {
	if ($('.video[data-video-youtube]').length > 0) {
		$('.video[data-video-youtube]').each(function (i, video) {
			var video_id = youtube_parser($(video).attr('data-video-youtube'));

			console.log(video_id);

			player = new YT.Player('player', {
				height: '200',
				width: '300',
				videoId: video_id,
				playerVars: {
					'autoplay': 0,
					'controls': 1,
					'rel': 0,
					'loop': 0,
					// 'mute': 1
				},
				events: {
					'onReady': onPlayerReady
				}
			});
		});
	}
}

function onPlayerReady (event) {
	var player = event.target;
	iframe = _ytPlayer('#player');
	setupListener();
}

function setupListener () {
	_ytPlayer('.play-btn').addEventListener('click', playYTVideo);
	_ytPlayer('.pause-btn').addEventListener('click', pauseYTVideo);
}

function playYTVideo () {
	$('.video').find('.play-btn').hide();
	$('.video').find('.player-overlay').hide();
	player.playVideo();
}

function pauseYTVideo () {
	player.pauseVideo();
}

function pauseYTVideo1 () {
	player1.pauseVideo();
}


/**
* Function to init map
*/

function initialize () {
	var center = new google.maps.LatLng(longtitude, latitude);
	var mapOptions = {
		zoom: zoom_level,
		center: center,
		mapTypeId: 'satellite'
	};

	map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
	addMarkerToMap( ajaxmarkers );

}

function addMarkerToMap( markers ) {
	markers.forEach( function ( marker ) {
		var Identifier = marker['Identifier'];
		var slug = marker['slug'];
		var cat = marker['category'];
		var pos = new google.maps.LatLng( marker['coordinates'][0], marker['coordinates'][1] );
		var name = marker['title'];
		var moreInfo = '<a href="#' + slug + '" class="map-moreinfo ' + cat + '" id="marker-' + slug + '" data-target-tab=".btn-' + cat + '"> More Info &gt; </a>';
		var content = '<h4>' + name + '</h4><p>' + moreInfo + '</p>';
		var marker1 = new google.maps.Marker({
			title: name,
			position: pos,
			category: cat,
			map: map
		});
		gmarkers1.push(marker1);
		refineries[Identifier] = marker1;
		// Marker click listener
		google.maps.event.addListener( marker1 , 'click', (function (m, c) {
			return function () {
				infowindow.setContent(c);
				infowindow.open(map, m);
				map.panTo(this.getPosition());
				map.setZoom(15);
			}
		})(marker1, content));
	});
	console.log( map );
	infowindow = new google.maps.InfoWindow({
		content: ''
	});
}

/**
* Function to filter markers by category
*/

function filterMarkers (category) {
	infowindow.close();
	for (var i = 0; i < gmarkers1.length; i++) {
		marker = gmarkers1[i];
		// If is same category or category not picked
		if (category == null || category == "all") {
			marker.setVisible(true);
		}
		else {
			if (marker.category == category || category.length === 0) {
				marker.setVisible(true);
			}
			// Categories don't match 
			else {
				marker.setVisible(false);
			}
		}
	}
}

function getUrlParameter (sParam) {
	var sPageURL = decodeURIComponent(window.location.search.substring(1)),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : sParameterName[1];
		}
	}
}


function isVisible (elment) {
	var vpH = $(window).height(), // Viewport Height
		st = $(window).scrollTop(), // Scroll Top
		y = $(elment).offset().top;

	return y <= (vpH + st);
}

function showfield (eventType) {
	if (eventType == 'others') {
		$("#eventTypeOther").show();
	}
	else {
		$("#eventTypeOther").hide();
	}
}

function setCookie (cname, cvalue) {
	var now = new Date();
	now.setTime(now.getTime() + 12 * 3600 * 1000);
	// now.setTime(now.getTime() + 2 * 60 * 1000); // 2 mins
	document.cookie = cname + "=" + cvalue + "; expires=" + now.toUTCString() + "; path=/";
}

function getCookie (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function delete_cookie (name) {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// Privacy Cookies
$(document).ready(function () {
	if ($.cookie('yourCookieName') != "active") $('.cookies-wrap').show();
	//Assign cookie on click
	$(document).on('click', '.cookies-close', function () {
		// console.log('click');
		$.cookie('yourCookieName', 'active', { expires: 30 }); // cookie will expire in one day
		$('.cookies-wrap').hide();
	});
});
